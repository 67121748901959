export default {
  'common.aboutUs': '關於伴',
  'common.members': '團隊成員',
  'common.space': '諮商空間',
  'common.order': '立即預約',
  'common.transportation': '交通方式',
  'common.rentSpace': '場地租借',
  'common.logout': '登出',
  'common.changeImage': '換圖'
}
