<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import 'assets/styles/reset.scss';
</style>

<style lang="scss" scoped>
.app {
  font-family: 微軟正黑體, 思源黑體, 蘋方黑體, 華康麗黑體, Helvetica, Arial,
    sans-serif, serif;
  height: 100%;
}
</style>
