import header from './header'
import common from './common'
import footer from './footer'

import admin from './admin'

export default {
  ...header,
  ...common,
  ...footer,

  ...admin
}
