const Admin = () => import(/* webpackChunkName: "admin" */ '@/admin/Admin')
const Login = () => import(/* webpackChunkName: "admin" */ '@/admin/Login')
const BannersControl = () =>
  import(/* webpackChunkName: "admin" */ '@/admin//BannersControl')

// import Admin from '@/admin/Admin'
// import Login from '@/admin/Login'
// import BannersControl from '@/admin//BannersControl'

const PATH_MAIN_PREFIX = '/admin'

export default [
  {
    path: PATH_MAIN_PREFIX,
    component: Admin,
    children: [
      {
        path: PATH_MAIN_PREFIX,
        name: 'adminHome'
      },
      {
        path: `${PATH_MAIN_PREFIX}/banners`,
        name: 'adminBanners',
        component: BannersControl
      },
      {
        path: `${PATH_MAIN_PREFIX}/login`,
        name: 'adminLogin',
        component: Login,
        meta: {
          hiddenSideMenu: true
        }
      }
    ]
  }
]
