import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang from '@/i18n'

Vue.use(VueI18n)

const I18N_DEFAULT_LOCALE = 'zh'

const i18n = new VueI18n({
  locale: I18N_DEFAULT_LOCALE,
  fallbackLocale: I18N_DEFAULT_LOCALE,
  messages: lang
})

function isI18nMessageExisted(key) {
  return i18n.te(key) || i18n.te(key, I18N_DEFAULT_LOCALE) || i18n.te(key, 'zh')
}

export { i18n, isI18nMessageExisted }
