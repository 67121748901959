export default {
  loginState({ state, commit }, value) {
    const isLogin = state.isLogin
    if (isLogin) {
      return
    }
    commit('SET_LOGIN_STATE', true)
    commit('SET_USER_INFO', value)
  }
}
