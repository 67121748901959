import Vue from 'vue'
import Router from 'vue-router'

import Main from './main'
import Admin from './admin'

Vue.use(Router)

const routerList = [...Main, ...Admin]

const router = new Router({
  mode: 'history',
  routes: routerList
})

export default router
