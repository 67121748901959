const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main')
const Home = () => import(/* webpackChunkName: "main" */ '@/views/Home')
const Members = () => import(/* webpackChunkName: "main" */ '@/views/Members')
const Space = () => import(/* webpackChunkName: "main" */ '@/views/Space')
const AboutCounseling = () =>
  import(/* webpackChunkName: "main" */ '@/views/service/AboutCounseling')
const Process = () =>
  import(/* webpackChunkName: "main" */ '@/views/service/Process')
const Fee = () => import(/* webpackChunkName: "main" */ '@/views/service/Fee')
const Appointment = () =>
  import(/* webpackChunkName: "main" */ '@/views/Appointment')
  const TeacherAppointment = () =>
  import(/* webpackChunkName: "main" */ '@/views/TeacherAppointment')
const Transportation = () =>
  import(/* webpackChunkName: "main" */ '@/views/Transportation')
const RentSpace = () =>
  import(/* webpackChunkName: "main" */ '@/views/RentSpace')
const News = () => 
  import(/* webpackChunkName: "main" */'@/views/News')


// import Main from '@/views/Main'
// import Home from '@/views/Home'
// import Members from '@/views/Members'
// import Space from '@/views/Space'
// import AboutCounseling from '@/views/service/AboutCounseling'
// import Process from '@/views/service/Process'
// import Fee from '@/views/service/Fee'
// import Appointment from '@/views/Appointment'
// import Transportation from '@/views/Transportation'
// import RentSpace from '@/views/RentSpace'
// import News from '@/views/News'
  

export default [
  {
    path: '',
    component: Main,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: '/service/aboutCounseling',
        name: 'aboutCounseling',
        component: AboutCounseling
      },
      {
        path: '/service/process',
        name: 'process',
        component: Process
      },
      {
        path: '/service/fee',
        name: 'fee',
        component: Fee
      },
      {
        path: '/members',
        name: 'members',
        component: Members
      },
      {
        path: '/space',
        name: 'space',
        component: Space
      },
      {
        path: '/appointment',
        name: 'appointment',
        component: Appointment
      },
      {
        path: '/teacher-appointment',
        name: 'teacherAppointment',
        component: TeacherAppointment
      },
      {
        path: '/transportation',
        name: 'transportation',
        component: Transportation
      },
      {
        path: '/rentSpace',
        name: 'rentSpace',
        component: RentSpace
      },
      {
        path: '/news',
        name: 'news',
        component: News
      }
    ]
  }
]
