import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueMq from 'vue-mq'
import vueMeta from 'vue-meta'
import { i18n } from '@/plugins/i18n'

Vue.config.productionTip = false

Vue.use(vueMq, {
  breakpoints: {
    sm: 480,
    md: 768,
    lg: Infinity
  }
})

Vue.use(vueMeta)

const isMobile = breakpoints => {
  return ['sm'].includes(breakpoints)
}

const isIpad = breakpoints => {
  return ['sm', 'md'].includes(breakpoints)
}

const isDesktop = breakpoints => {
  return ['lg'].includes(breakpoints)
}

Vue.mixin({
  computed: {
    isMobile() {
      return isMobile(this.$mq)
    },
    isIpad() {
      return isIpad(this.$mq)
    },
    isDesktop() {
      return isDesktop(this.$mq)
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
